$(function(){
	$("#inquiryform input").change(function(){
		var _p = $(this).parent("div").parent("div");
		if ($(this).val() == "") {
			_p.addClass("has-error");
		} else {
			_p.removeClass("has-error");
		}
	});
	$("#inquiryformbutton").click(function(){
		var _name =$('#i_name').val();
		var _tel =$('#i_tel').val();
		var _mail =$('#i_mail').val();
		if (_name =="" ) {
			$(".alert-danger").html("Name can not be empty!").show();
			return;
		}
		if (_tel=="") {
			$(".alert-danger").html("Tel can not be empty!").show();
			return;
		}
		if(_mail==""){
			$(".alert-danger").html("Email can not be empty!!").show();
			return;
		}
		 var search_str = /^[\w\-\.]+@[\w\-\.]+(\.\w+)+$/;
		 if(!search_str.test($("#i_mail").val())){
		 	$(".alert-danger").html("Please input right email !").show();
			 $('#i_mail').focus();
			 return false;
		 }

		if($("#i_content").val()==""){
			$(".alert-danger").html("Content can not be empty!").show();
			return;
		}
		$.post("http://www.btonnet.com/app/message.php?action=save", $("#inquiryform").serialize(),function(data){
			$(".alert-danger").html(data).show();
		});
		$(this).attr('disabled',"true");
	});
	$("#inquiryrest").click(function(){
		$('#inquiryformbutton').removeAttr("disabled");
	});
});
